import React from 'react'
import bemCx from 'bem-modifiers'

import { Redirect } from '~/components/Link'

const UserMenuView = ({ handleLogOut, isBlack, t, newClient }) => {
  return (
    <div className={bemCx('user-menu', [{ 'black': isBlack }])}>
      <Redirect route={newClient ? 'user/reservations' : 'my-reservations'} newClient={newClient}>
        <div className='user-menu__item'>
          {t(`user_menu.my_reservations`)}
        </div>
      </Redirect>
      <div
        className='user-menu__item'
        onClick={handleLogOut}
      >
        {t(`user_menu.log_out`)}
      </div>
    </div>
  )
}

export default UserMenuView
