import { useQuery } from '@apollo/client'
import get from 'lodash/get'

import { useGraphModalActions } from '~/hooks/useModal'

import { GET_USER_DATA } from '~/utils/queries.gql'
import { setSalesmanagoCookie, hasWindow, handleRegulationModal } from '~/utils/helpers'

export default (skipUserQuery = false) => {
  const { openModal } = useGraphModalActions()
  const { data, loading } = useQuery(
    GET_USER_DATA,
    {
      fetchPolicy: 'cache-and-network',
      skip: skipUserQuery,
      onCompleted: () => {
        const user = get(data, 'user')
        const regulationStatus = get(data, 'user.regulationStatus')

        user && handleRegulationModal({ regulationStatus, openModal })
        if (hasWindow()) {
          setSalesmanagoCookie(user)
        }
      }
    }
  )

  return {
    user: get(data, 'user'),
    userLoading: loading
  }
}
