import { TAGS, TAG_TO_OMIT, COMMON_HEADER_LINKS } from '~/utils/consts'
const { CATEGORY, CUISINE, DIET, DISH } = TAGS

export default {
  name: 'World Class Cocktail Festival',
  id: 'festival-cf',

  url: 'cocktailfestival.pl',
  query: 'cf',

  fb: 'https://www.facebook.com/worldclasscocktailfestival/',
  instagram: 'https://www.instagram.com/worldclass_cocktailfestival/',
  email: 'info@cocktailfestival.pl',
  showAd: false,

  faq: {
    offset: {
      mobile: 30,
      tablet: 30,
      desktop: 66,
      intersection: 28
    }
  },

  tagsToOmit: [...TAG_TO_OMIT, CATEGORY, CUISINE, DIET, DISH],
  headerLinks: [...COMMON_HEADER_LINKS]
}
