import React from 'react'
import bemCx from 'bem-modifiers'
import Select, { components } from 'react-select'
import DropdownTransitionWrapper from '~/components/DropdownTransitionWrapper'

const OptionLabel = (valueContainerIcon) => (option, props) => (
  <div className='react-select__option-label option-label'>
    {!valueContainerIcon && option.icon && <span className={`option-label__icon i-${option.icon}`} />}
    {
      (valueContainerIcon && props.context === 'value')
      && <span className={`option-label__icon i-${valueContainerIcon}`} />
    }
    <span className='option-label__label'>{option.label}</span>
  </div>
)

class DropdownIndicator extends React.Component {
  render () {
    const modifiers = this.props.modifiers
    let modifiersArray = modifiers || []
    if (typeof modifiers === 'string') modifiersArray = [modifiers]

    return (
      <span
        className={this.props.alternativePrefix ?
          bemCx(
            'arrow-indicator',
            [{ 'focused': this.props.isMenuOpen }, { 'disabled': this.props.isDisabled }], [...modifiersArray]
          )
          : bemCx(
            'arrow-indicator',
            [{ 'focused': this.props.isMenuOpen }, { 'disabled': this.props.isDisabled }]
          )}
      />
    )
  }
}

class SelectContainer extends React.Component {
  render () {
    const { children, ...props } = this.props

    let selectElements = children.reduce((elements, child, i) => {
      if (React.isValidElement(child)) {
        elements.push(
          React.cloneElement(child, {
            key: i
          })
        )
      }
      return elements
    }, [])

    return (
      <components.SelectContainer {...props}>
        <DropdownTransitionWrapper>
          {selectElements}
        </DropdownTransitionWrapper>
      </components.SelectContainer>
    )
  }
}

export default class DropdownSelect extends React.Component {
  state = {
    isMenuOpen: false
  }

  setMenuOpen = () => this.setState({ isMenuOpen: true })

  setMenuClosed = () => this.setState({ isMenuOpen: false })

  renderDropdownIndicator = () => <DropdownIndicator
    isMenuOpen={this.state.isMenuOpen}
    isDisabled={this.props.isDisabled}
    alternativePrefix={this.props.alternativePrefix}
    modifiers={this.props.modifiers}
  />

  renderSelectContainer = (props) => {
    return <SelectContainer {...props} />
  }

  render () {
    const {
      value,
      options,
      components,
      modifiers,
      className,
      hideSelectedOptions = true,
      valueContainerIcon,
      disabled,
      component: Component = Select,
      withArrow = true,
      alternativePrefix = false,
      ...props
    } = this.props
    let modifiersArray = modifiers || []
    if (typeof modifiers === 'string') modifiersArray = [modifiers]

    const classPrefix = alternativePrefix ? bemCx('react-select', [...modifiersArray]) : 'react-select'

    return (
      <Component
        {...props}
        isDisabled={disabled}
        hideSelectedOptions={hideSelectedOptions}
        className={bemCx('react-select', [...modifiersArray, 'styled'], className)}
        classNamePrefix={classPrefix}
        value={value}
        options={options}
        formatOptionLabel={OptionLabel(valueContainerIcon)}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: withArrow ? this.renderDropdownIndicator : null,
          SelectContainer: this.renderSelectContainer,
          ...components
        }}
        autosize
        onMenuOpen={this.setMenuOpen}
        onMenuClose={this.setMenuClosed}
        captureMenuScroll={false}
      />
    )
  }
}
