import React from 'react'

import withUser from '~/hoc/withUser/withUser'
import { withGraphModalActions } from '~/hoc/withGraphModalActions'

import DesktopNavigationView from './DesktopNavigationView'

import { scrollTo, formatLocationQuery, handleSponsorsLink } from '~/utils/helpers'
import { DEFAULT_HEADER_PAGES, CAREER_LINK } from '~/utils/consts'
import withFestivalContext from '../../hoc/withFestivalContext'

@withUser
@withGraphModalActions
@withFestivalContext
export default class DesktopNavigation extends React.Component {
  state = {
    isMenuUserVisible: false,
  }

  componentDidMount = () => {
    const { user } = this.props
    if (user === null) this.handleLoginRedirect()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.user === undefined && this.props.user === null) {
      this.handleLoginRedirect()
    }
  }

  handleLoginRedirect = () => {
    const { router, openModal } = this.props
    const isLoginRedirect = router.query.loginRedirect
    isLoginRedirect && openModal('authenticate')
  }

  handleMenuUser = () => {
    const { openModal } = this.props

    if (this.isUserVerified) {
      this.setState({ isMenuUserVisible: !this.state.isMenuUserVisible })
    } else {
      openModal('authenticate')
    }
  }

  closeTooltip = () => {
    this.setState({ isMenuUserVisible: false })
  }

  handleScrollTo = (sectionId, sectionOffset, e) => {
    scrollTo(sectionId, { duration: 350, offset: -sectionOffset })(e)
  }

  get isUserVerified () {
    const { user } = this.props
    return user && user.verified
  }

  get locationStr () {
    return formatLocationQuery(this.props.location)
  }

  get partnersLink () {
    const { selectedFestivalCode, isListingRoute } = this.props
    return handleSponsorsLink({ selectedFestivalCode, isListingRoute, handleScrollTo: this.handleScrollTo })
  }

  get careersLink () {
    return CAREER_LINK
  }

  render () {
    const { router } = this.props
    const { prevReservationCode } = router.query

    const isIndexRoute = router.route === '/'
    const isRestaurants = router.route === '/restaurants'
    const isBlack = router.route === '/kinley' || router.route === '/cola'
    const isDefaultHeader = DEFAULT_HEADER_PAGES.includes(router.route)

    const modifiers = {
      'index': isIndexRoute,
      'default': isDefaultHeader,
      'restaurants': isRestaurants,
      'black': isBlack
    }

    return (
      <DesktopNavigationView
        {...this.state}
        {...this.props}
        modifiers={modifiers}
        handleMenuUser={this.handleMenuUser}
        handleScrollTo={this.handleScrollTo}
        setLoading={this.setLoading}
        closeTooltip={this.closeTooltip}
        prevReservationCode={prevReservationCode}
        locationStr={this.locationStr}
        partnersLink={this.partnersLink}
        careersLink={this.careersLink}
        isBlack={isBlack}
        isUserVerified={this.isUserVerified}
      />
    )
  }
}
