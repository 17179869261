export const waitForElement = ({ getEl, timeout = 3000 }) => {
  const intervalSeconds = 100
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line prefer-const
    let timeoutTimer
    const interval = setInterval(() => {
      const el = getEl()

      if (el) {
        clearInterval(interval)
        clearTimeout(timeoutTimer)
        resolve(el)
      }
    }, intervalSeconds)

    timeoutTimer = setTimeout(() => {
      clearInterval(interval)
      reject('Waiting for element timed out')
    }, timeout)
  })
}
