import React, { useState, useEffect } from 'react'
import { isEmpty } from 'ramda'

import useLocationSync from '~/hooks/useLocationSync'

import GeolocationDropdownSelectView from './GeolocationDropdownSelectView'

import { DEFAULT_LOCATION } from '~/utils/consts'

export default ({ regions, setUrl, handleReset, router, ...props }) => {

  if (isEmpty(regions)) return (<></>)

  const [selectedLocation, setSelectedLocation] = useState(null)
  const [availableRegions, setAvailableRegions] = useState([DEFAULT_LOCATION])

  const formatLocationQuery = queryLocation => {
    const queryLocationArr = queryLocation.split('-')
    return {
      id: queryLocationArr[0],
      name: queryLocationArr[1]
    }
  }
  const queryLocation = router.query.location && formatLocationQuery(router.query.location)
  const syncLocation = useLocationSync({ selectedLocation, setUrl, router })
  const location = queryLocation || syncLocation

  const getCitiesOptions = () => {
    const citiesOptions = regions.map(region => ({ value: region.id, label: region.name }))
    setAvailableRegions(citiesOptions)
  }

  useEffect(
    () => { regions && !isEmpty(regions) && getCitiesOptions() },
    [regions]
  )

  const handleChange = city => {
    setSelectedLocation(city)
    handleReset && handleReset(city)
  }

  return (
    <GeolocationDropdownSelectView
      {...props}
      citiesOptions={availableRegions}
      changeSelectedCity={handleChange}
      selectedLocation={location}
    />
  )
}
