import cookie from 'cookie'
import { path as ramdaPath } from 'ramda'

const getExistingCookieStrings = (res) => {
  const existingHeaders = res.getHeader('Set-Cookie')
  let existingCookieStrings = []

  if (typeof existingHeaders === 'string') {
    existingCookieStrings = [existingHeaders]
  } else if (Array.isArray(existingHeaders)) {
    existingCookieStrings = existingHeaders
  }
  return existingCookieStrings
}

export const ssrAppendCookieStringToHeader = (cookieString, res) => {
  const existingCookieStrings = getExistingCookieStrings(res)

  res.setHeader('Set-Cookie', [cookieString, ...existingCookieStrings])
}

export const setCookies = (key, value, res, options = {}) => {
  const _opts = {
    path: '/',
    sameSite: 'lax',
    secure: true,
    ...options,
  }
  const cookieString = cookie.serialize(key, `${value}`, _opts)
  if (res) {
    ssrAppendCookieStringToHeader(cookieString, res)
  } else {
    document.cookie = cookieString
  }
}

export const setCookiesAsJson = (key, value, res, options = {}) => {
  setCookies(key, JSON.stringify(value), res, options)
}

export const getCookie = (req, key) => {
  let cookieString
  if (req && req.headers && req.headers.cookie) {
    cookieString = req.headers.cookie
  } else if (typeof document !== 'undefined') {
    cookieString = document.cookie
  }
  return (cookieString && cookie.parse(cookieString)[key])
    ? cookie.parse(cookieString)[key]
    : null
}

export const getCookieAsParsedJson = (req, key) => {
  const cookie = getCookie(req, key)
  return cookie ? JSON.parse(cookie) : null
}

export const deleteCookie = (res, key, options = {}) => {
  const expiredOptions = { path: '/', expires: new Date(), ...options }
  if (res) {
    res.setHeader('Set-Cookie', cookie.serialize(key, ''), expiredOptions)
  } else {
    document.cookie = cookie.serialize(key, '', expiredOptions)
  }
}

const getCookieStrFromRes = (res) => {
  const existingCookieStrings = getExistingCookieStrings(res)

  return existingCookieStrings.reduce((str, cookieStr) => {
    str += ` ${cookieStr.split(';')[0]};`
    return str
  }, '')
}

export const mergeResAndReqCookie = ({ req, res }) => {

  const reqCookie = `${ramdaPath(['headers', 'cookie'], req)};` || ''
  const resCookie = res ? getCookieStrFromRes(res) : ''

  return `${reqCookie}${resCookie}`
}
