import React from 'react'
import { withNamespaces } from '>/i18n'
import bemCx from 'bem-modifiers'

import { Redirect } from '~/components/Link'
import { getFaqRoute } from '~/utils/helpers'
// import LanguageSwitcher from '~/components/LanguageSwitcher'

import { HEADER_LINK_NAMES } from '~/utils/consts'
import config from '~/config'
import { getIsFestivalPurchaseFlowNewClient } from '~/utils/newClient'

const {
  // RESTAURANTS,
  ABOUT,
  FAQ,
  SIDE_EVENTS,
  GIFT_EVENTS,
  PARTNERS,
  JOIN_US,
  CAREERS,
  BLOG
} = HEADER_LINK_NAMES

const MobileMenuView = ({
  festivalId,
  animation,
  linkCheck,
  handleScrollTo,
  handleLinkClick,
  locationStr,
  listingLinkParams,
  festivalName,
  selectedFestivalCode,
  isBlack,
  partnersLink,
  careersLink,
  t
}) => {
  const isPurchaseFlowNewClient = getIsFestivalPurchaseFlowNewClient(selectedFestivalCode)

  return (
    <div className={bemCx('mobile-menu', [animation, { 'black': isBlack }])}>
      {/* {linkCheck(RESTAURANTS) && <Redirect
        route='restaurants'
        params={listingLinkParams}
        className='mobile-menu__link'
        onClick={handleLinkClick}
      >
        {t('links.listing')}
      </Redirect>} */}
      {linkCheck(ABOUT) && <a
        onClick={e => handleScrollTo('explanation-section', 40, e)}
        className='mobile-menu__link'
      >
        {t(`links.about_${festivalId ? 'festival' : 'club'}`)}
      </a>}
      {linkCheck(FAQ, festivalName) && <Redirect
        route={getFaqRoute(festivalName || selectedFestivalCode)}
        className='mobile-menu__link'
        onClick={handleLinkClick}
        newClient={isPurchaseFlowNewClient}
      >
        {t('links.faq')}
      </Redirect>}
      {linkCheck(SIDE_EVENTS) && <Redirect
        route='chefsmenu'
        // params={{ location: locationStr }}
        className='mobile-menu__link'
        onClick={handleLinkClick}
      >
        {t('links.side_events')}
      </Redirect>}
      {linkCheck(GIFT_EVENTS) && <Redirect
        route='gift-events-listing'
        className='mobile-menu__link'
        onClick={handleLinkClick}
      >
        {t('links.gift_events')}
      </Redirect>}
      {linkCheck(PARTNERS) && <a
        href={partnersLink.href}
        onClick={partnersLink.onClick}
        className='mobile-menu__link'
      >
        {t('links.partners')}
      </a>}
      {festivalId && linkCheck(GIFT_EVENTS) && <Redirect
        route='gift-events-listing'
        className='mobile-menu__link'
        newClient={isPurchaseFlowNewClient}
      >
        {t('links.voucher_purchase')}
      </Redirect>}
      {linkCheck(JOIN_US) && <a
        onClick={e => handleScrollTo('cooperation-section', 20, e)}
        className='mobile-menu__link'
      >
        {t('links.join_us')}
      </a>}
      {linkCheck(CAREERS) && <a
        href={isPurchaseFlowNewClient
          ? `${config().newClubUrl}/career`
          : careersLink}
        target='_blank'
        className='mobile-menu__link'
      >
        {t('links.careers')}
      </a>}
      {linkCheck(BLOG) && <Redirect
        route='blog'
        className='mobile-menu__link'
      >
        {t('links.blog')}
      </Redirect>}
      <div className='mobile-menu__separator' />
      {/* <LanguageSwitcher /> */}
    </div>
  )
}

export default withNamespaces(['common'])(MobileMenuView)
