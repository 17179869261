const staticRoutes = [
  { name: 'main', pattern: '/', page: 'index', priority: 1 },
  { name: 'restaurants', pattern: '/restaurants', page: 'restaurants', priority: 1 },
  { name: 'my-reservations', pattern: '/my-reservations', page: 'my-reservations', priority: 0.8 },
  { name: 'early-reservation', pattern: '/early-reservation', page: 'early-reservation', priority: 0.8 },
  { name: 'order', pattern: '/order', page: 'order', priority: 0.8 },
  { name: 'order-edit', pattern: '/order-edit', page: 'order-edit', priority: 0.8 },
  { name: 'faq', pattern: '/faq', page: 'faq', priority: 1 },
  { name: 'chefsmenu', pattern: '/chefsmenu', page: 'side-events-listing', priority: 0.8 },
  { name: 'ginweek-vouchers', pattern: '/ginweek-vouchers', page: 'side-events-listing', priority: 0.8 },
  { name: 'side-events-listing', pattern: '/side-events-listing', page: 'side-events-listing', priority: 0.8 },
  { name: 'gift-events-listing', pattern: '/gift-events-listing', page: 'gift-events-listing', priority: 0.8 },
  { name: 'group-reservations', pattern: '/group-reservations', page: 'group-reservations', priority: 0.8 },
  { name: 'order-success', pattern: '/order/result/success', page: 'order/success', priority: 0.8 },
  { name: 'order-failure', pattern: '/order/result/failure', page: 'order/failure', priority: 0.8 },
  { name: 'event-order', pattern: '/event-order', page: 'event-order', priority: 0.8 },
  { name: 'evaluation', pattern: '/my-reservations/evaluation', page: 'evaluation', priority: 0.8 },
  { name: 'reservation-edit', pattern: '/reservation-edit', page: 'reservation-edit', priority: 0.5 },
  { name: 'regulations', pattern: '/regulations', page: 'regulations', priority: 1 },
  { name: 'voucher-details', pattern: '/voucher-details', page: 'voucher-details', priority: 0.5 },
  { name: 'voucher-order', pattern: '/voucher-order', page: 'voucher-order', priority: 0.5 },
  { name: 'login', pattern: '/login', page: 'login', priority: 0 },
  { name: 'register', pattern: '/register', page: 'register', priority: 0 },
  { name: 'kinley', pattern: '/brand/kinley', page: 'kinley', priority: 0.1 },
  { name: 'delice', pattern: '/brand/delice', page: 'delice', priority: 0.1 },
  { name: 'cola', pattern: '/brand/coca-cola', page: 'cola', priority: 0.1 },
  { name: 'apple-login', pattern: '/login/apple', page: 'apple', priority: 0 },
  { name: 'become-partner', pattern: '/become-partner', page: 'become-partner', priority: 0.1 },
  { name: 'festival-rw', pattern: '/restaurantweek', page: 'festival/rw', priority: 1 },
  { name: 'faq-rw', pattern: '/restaurantweek/faq', page: 'festival/rw/faq', priority: 1 },
  { name: 'festival-gw', pattern: '/ginweek', page: 'festival/gw', priority: 1 },
  { name: 'faq-gw', pattern: '/ginweek/faq', page: 'festival/gw/faq', priority: 1 },
  { name: 'festival-pw', pattern: '/pizzaweek', page: 'festival/pw', priority: 1 },
  // { name: 'faq-pw', pattern: '/pizzaweek/faq', page: 'festival/pw/faq', priority: 1 },
  { name: 'festival-fdw', pattern: '/finediningweek', page: 'festival/fdw', priority: 1 },
  { name: 'faq-fdw', pattern: '/finediningweek/faq', page: 'festival/fdw/faq', priority: 1 },
  { name: 'festival-cc', pattern: '/bliscyprzystole', page: 'festival/cc', priority: 1 },
  { name: 'faq-cc', pattern: '/bliscyprzystole/faq', page: 'festival/cc/faq', priority: 1 },
  { name: 'festival-bw', pattern: '/breakfastweek', page: 'festival/bw', priority: 1 },
  { name: 'faq-bw', pattern: '/breakfastweek/faq', page: 'festival/bw/faq', priority: 1 },
  { name: 'menus', pattern: '/menus', page: 'menus', priority: 1 },
  { name: 'klarna-help', pattern: '/klarna-help', page: 'klarna-help', priority: 0.1 }
]

module.exports = staticRoutes
