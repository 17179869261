import React, { useCallback } from 'react'
import bemCx from 'bem-modifiers'
import { useQuery } from '@apollo/client'
import { withRouter } from 'next/router'
import get from 'lodash/get'
import { isEmpty } from 'ramda'

import MobileNavigation from '~/components/MobileNavigation'
import DesktopNavigation from '~/components/DesktopNavigation/DesktopNavigationGql'
// eslint-disable-next-line max-len
import DesktopNavigationOnScroll from '~/components/Festival/common/DesktopNavigationOnScroll/DesktopNavigationOnScrollGql'

import { GET_SPONSORS, GET_FESTIVAL_EDITION } from '~/utils/queries.gql'
import { HEADER_LINK_NAMES } from '~/utils/consts'
import config from '~/config'
import { CouponBar } from '~/components/CouponBar'

const {
  ABOUT,
  JOIN_US,
  PARTNERS
} = HEADER_LINK_NAMES

const Header = ({ router, modifiers }) => {
  const route = router.route
  const isBrandRoute = route === '/kinley' || route === '/delice' || route === '/cola'
  const isListingRoute = route === '/restaurants'
  const isGiftRoute = route === '/gift-events-listing'
  const isFestivalLandingRoute = route ? route.match(/^\/festival\//) : false
  const includedKlarnaRoutes = ['/festival/bw', '/festival/rw', '/festival/fdw']
  const isIncludedKlarnaRoute = includedKlarnaRoutes.includes(route)

  const { data: selectedFestivalData } = useQuery(GET_FESTIVAL_EDITION, {
    variables: { id: router.query.festivalFilter },
    skip: !router.query.festivalFilter,
    // workaround for skipping issue
    fetchPolicy: !router.query.festivalFilter ? 'cache-only' : 'cache-and-network'
  })
  const selectedFestivalCode = get(selectedFestivalData, 'festivalEdition.festival.code')

  const { data: sponsorsData, loading: sponsorsLoading } = useQuery(GET_SPONSORS, {
    variables: {
      clubSponsors: true,
    },
    skip: isListingRoute
  })

  // Needed because skip in sponsors query doesn't work
  let isSponsor = true
  if (!isListingRoute) {
    isSponsor = sponsorsLoading
      ? true
      : get(sponsorsData, 'sponsors') && !isEmpty(get(sponsorsData, 'sponsors.nodes'))
  }

  const linkCheck = useCallback((linkName, festivalName) => {
    const rcLinks = get(config(festivalName), 'festival.headerLinks', {})

    const giftLinksHidden = [ABOUT, PARTNERS]
    const brandLinks = [JOIN_US]

    if (linkName === PARTNERS && !isSponsor) return false
    if (isGiftRoute && giftLinksHidden.includes(linkName)) return false
    if (rcLinks.includes(linkName)) {
      return true
    } else {
      if (brandLinks.includes(linkName) && isBrandRoute) return true
      return false
    }
  }, [isSponsor])

  const commonProps = {
    linkCheck,
    selectedFestivalCode,
    isListingRoute,
    router,
  }

  return (
    <>
      {config().commonNinjaWidgetIds.map(id => (
        <CouponBar key={id} widgetId={id} />
      ))}
      {isIncludedKlarnaRoute && config().klarna.enableBranding &&
        <div id='headerklarna' className={bemCx('headerklarna', modifiers)}>
          <klarna-placement
            data-key='top-strip-promotion-auto-size'
            data-locale='pl-PL' />
        </div>
      }
      <header id='header' className={bemCx('header', modifiers)}>
        <MobileNavigation {...commonProps} />
        <DesktopNavigation {...commonProps} />
      </header>
      {isFestivalLandingRoute && <DesktopNavigationOnScroll router={router} />}
    </>
  )
}

export default withRouter(Header)
