import React from 'react'
import bemCx from 'bem-modifiers'
import { ErrorMessage } from 'formik'
import { withNamespaces, Trans } from '>/i18n'
import MaskedInput from 'react-text-mask'

const CustomInput = ({ mask, ...props }) => mask
  ? <MaskedInput {...props} mask={mask} />
  : <input {...props} />

const InputField = ({
  placeholder,
  disabled,
  className,
  modifiers,
  iconLeft,
  iconRight,
  form,
  required,
  field,
  label,
  onChange,
  checkBlur,
  autofocus,
  errorLeft,
  t,
  type = 'text',
  submitOnBlur,
  disableDafaultEnter = false,
  handleEnter,
  hiddenLabel,
  mask,
}) => {

  const handleChange = (event) => {
    if (onChange) {
      onChange(event)
    }
    field.onChange(event)
  }

  const handleBlur = () => {
    checkBlur && form.setFieldTouched(field.name, true)

    submitOnBlur && submitOnBlur()
  }

  const handleKeyPress = (e) => {
    if (disableDafaultEnter && e.key === 'Enter') {
      e.preventDefault()
      form.setFieldTouched(field.name, true)
      handleEnter && handleEnter()
    }
  }

  const hasError = !!(form.touched[field.name] && form.errors[field.name])

  return (
    <div className={bemCx('input-field', [modifiers, { 'error': hasError, required, disabled }], className)}>
      {label && <label className={bemCx('input-field__label', { hidden: hiddenLabel })} htmlFor={field.name}>
        {label}
      </label>}
      <div className='input-field__input-container'>
        {iconLeft && <span className={bemCx('input-field__icon', 'left', `i-${iconLeft}`)} />}
        <CustomInput
          onChange={handleChange}
          onBlur={handleBlur}
          value={field.value || ''}
          id={field.name}
          name={field.name}
          autoComplete='nope'
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          className={bemCx('input-field__input', { 'icon-left': iconLeft, 'icon-right': iconRight })}
          autoFocus={autofocus}
          onKeyPress={handleKeyPress}
          mask={mask}
        />
        {iconRight && <span className={bemCx('input-field__icon', 'right', `i-${iconRight}`)} />}
      </div>
      <ErrorMessage
        name={field.name}
        render={message => {
          const simpleError = typeof message === 'string'

          return (
            <div className={bemCx('input-field__error', [modifiers, { 'left': errorLeft }])}>
              {simpleError
                ? <span>{t(message)}</span>
                : <Trans i18nKey={message.key} count={message.count}>
                  <span>{{ count: message.count }}</span>
                </Trans>
              }
            </div>
          )
        }}
      />
    </div>
  )
}

export default withNamespaces('errors')(InputField)
