import React from 'react'
import { withNamespaces } from '>/i18n'
import get from 'lodash/get'

import { hasWindow, setLocationAndPeopleQuery, getPeopleOptions } from '~/utils/helpers'

import DesktopNavigationOnScrollView from './DesktopNavigationOnScrollView'
import { FESTIVAL_EDITION_STATES } from '~/utils/consts'

const MENU_MOBILE_ANIMATION_HEIGHT = 400

export default
@withNamespaces('common')
class DesktopNavigationOnScroll extends React.Component {
  state = {
    people: this.peopleOptions,
    loading: false
  }

  componentDidMount () {
    hasWindow() && window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount () {
    hasWindow() && window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const mobileNavigation = hasWindow() && document.querySelector('.desktop-navigation-on-scroll')

    if (mobileNavigation) {
      if (hasWindow() && window.scrollY <= MENU_MOBILE_ANIMATION_HEIGHT) {
        mobileNavigation.style.cssText = 'display:none'
      } else if (hasWindow() && window.scrollY >= MENU_MOBILE_ANIMATION_HEIGHT && window.innerWidth >= 992) {
        mobileNavigation.style.cssText = 'display: flex; position:fixed; top:0'
      }
    }
  }

  setLoading = () => this.setState({ loading: true })

  setPeopleValue = value => {
    this.setState({ people: value })
  }

  get priceSentence () {
    const { festivalData } = this.props
    const reservationPrice = festivalData ? festivalData.price : 0
    const price = reservationPrice.toFixed(2).replace('.', ',')

    return price.endsWith(',00') ? price.slice(0, -3) : price
  }

  get buttonSentence () {
    if (this.props.isVoucher) {
      return 'voucher'
    }
    switch (this.props.festivalName) {
      case 'gw':
        return 'buy_ticket'
      case 'bw':
        return 'buy_menu'
      case 'fdw':
        return 'fdw_search'
      default:
        return 'search'
    }
  }

  get peopleOptions () {
    const { minPeopleCount, maxPeopleCount } = this.props
    return getPeopleOptions({ minPeopleCount, maxPeopleCount })
  }

  get redirectParams () {
    const { people } = this.state
    const { isVoucher, location, festivalData, festivalId } = this.props

    return isVoucher
      ? { festivalId }
      : {
        ...setLocationAndPeopleQuery(people.value, location),
        festivalFilter: get(festivalData, 'id')
      }
  }

  render () {
    const { festivalName, isVoucher, festivalData } = this.props

    const isPresale = get(festivalData, 'state') === FESTIVAL_EDITION_STATES.PRESALE
    const festivalId = get(festivalData, 'id')

    return (
      <DesktopNavigationOnScrollView
        {...this.state}
        isVoucher={isVoucher}
        redirectParams={this.redirectParams}
        festivalName={festivalName}
        guestOptions={this.peopleOptions}
        priceSentence={this.priceSentence}
        buttonSentence={this.buttonSentence}
        setLoading={this.setLoading}
        setPeopleValue={this.setPeopleValue}
        isPresale={isPresale}
        festivalId={festivalId}
      />
    )
  }
}
