import React from 'react'
import { withNamespaces } from '>/i18n'

import DropdownSelect from '~/components/DropdownSelect'
import { LinkButton } from '~/components/Button'
import GeolocationDropdownSelect from '~/components/GeolocationDropdownSelect/GeolocationDropdownSelectGql'
import {
  GwLogo,
  CcLogo,
  isUniqueFestival,
  Logo
} from '~/components/DesktopNavigation/DesktopNavigationView'
import { processEnv } from '../../../../utils/helpers'
import {
  getIsFestivalPurchaseFlowNewClient,
  getNewClientEarlyReservationLink,
  getNewClientListingLink,
  getNewClientVoucherLink
} from '~/utils/newClient'

const DesktopNavigationOnScrollView = ({
  t,
  festivalName,
  priceSentence,
  buttonSentence,
  loading,
  setLoading,
  setPeopleValue,
  guestOptions,
  people,
  isVoucher,
  redirectParams,
  isPresale,
  festivalId
}) => {
  const getRoute = () => {
    if (festivalName === 'pw') {
      return processEnv.NEW_CLUB_URL + '/restaurants/festival/pizzaweek'
    } else if (getIsFestivalPurchaseFlowNewClient(festivalName)) {
      if (isVoucher) {
        return getNewClientVoucherLink(festivalId, redirectParams)
      }
      if (isPresale) {
        return getNewClientEarlyReservationLink(festivalId, redirectParams)
      }
      return getNewClientListingLink(festivalName, redirectParams)
    } else if (isVoucher) {
      return 'voucher-details'
    } else {
      return 'restaurants'
    }
  }

  return (
    <div className='desktop-navigation-on-scroll'>
      <div className='desktop-navigation-on-scroll__container'>
        {festivalName === 'gw' && (
          <GwLogo
            className='desktop-navigation-on-scroll'
            festivalName={festivalName}
            t={t}
          />
        )}
        {festivalName === 'cc' && (
          <CcLogo
            className='desktop-navigation-on-scroll'
            festivalName={festivalName}
            t={t}
          />
        )}
        {!isUniqueFestival(festivalName) && (
          <Logo
            className='desktop-navigation-on-scroll'
            festivalName={festivalName}
            t={t}
          />
        )}
        <div className='desktop-navigation-on-scroll__filters'>
          {isVoucher ? (
            <span className='desktop-navigation-on-scroll__voucher-text'>
              {t('common:header.voucher')}
            </span>
          ) : (
            <>
              <GeolocationDropdownSelect
                instanceId='location-select-desktop'
                modifiers={
                  festivalName === 'gw'
                    ? ['geolocation', 'hero', 'white-gw']
                    : ['frameless', 'triangle-menu']
                }
                alternativePrefix={festivalName === 'gw'}
              />
              <span className='desktop-navigation-on-scroll__separator' />
              <DropdownSelect
                modifiers={
                  festivalName === 'gw'
                    ? ['people', 'white-gw']
                    : ['frameless', 'triangle-menu']
                }
                valueContainerIcon='user'
                options={guestOptions}
                defaultValue={people}
                value={people}
                onChange={setPeopleValue}
                isSearchable={false}
                hideSelectedOptions={false}
                alternativePrefix={false}
              />
              <span className='desktop-navigation-on-scroll__separator' />
              {festivalName !== 'gw' && (
                <div className='desktop-navigation-on-scroll__price'>
                  {(festivalName === 'cc' || festivalName === 'fdw') &&
                    t(`common:from`)}{' '}
                  {priceSentence}
                  {t(
                    `common:${
                      festivalName === 'fdw' || festivalName === 'dw'
                        ? `currency_${festivalName}`
                        : 'currency'
                    }.pln_per_person`
                  )}
                </div>
              )}
            </>
          )}
          <LinkButton
            modifiers={['primary', 'full-width', 'navbar']}
            params={redirectParams}
            route={getRoute()}
            label={
              festivalName === 'gw'
                ? t(`homepage-gw:navbar.button`, {
                  price: parseFloat(priceSentence).toFixed(0)
                })
                : t(`common:button.${buttonSentence}`)
            }
            onClick={() => setLoading(true)}
            loading={loading}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(
  withNamespaces(['common'])(DesktopNavigationOnScrollView)
)
