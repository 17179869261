import { TAG_TO_OMIT, TAGS } from '~/utils/consts'

export default {
  name: 'PizzaWeek',
  id: 'festival-pw',

  url: 'pizzaweek.pl',
  query: 'pw',

  fb: 'https://www.facebook.com/restaurantclubpl',
  instagram: 'https://www.instagram.com/explore/tags/restaurantweekpolska',
  email: 'info@restaurantclub.pl',
  showAd: true,

  faq: {
    offset: {
      mobile: 30,
      tablet: 30,
      desktop: 66,
      intersection: 28
    }
  },

  tagsToOmit: [...TAG_TO_OMIT, TAGS.DELIVERY_REGION],
  headerLinks: []
}
