import React from 'react'
import bemCx from 'bem-modifiers'
import { withRouter } from 'next/router'
import moment from 'moment'

import { ACCESS, MODAL_NAMES, FESTIVAL_EDITION_STATES, REGULATION_STATUS } from '~/utils/consts'
import { getCookieAsParsedJson } from '~/utils/cookie'
import { getFestivalState, getFestival, getFestivalRangeAndPrice, redirect, getConsentValue } from '~/utils/helpers'
import { basicAuthMiddleware } from '~/utils/auth'
import { updateQueryParam } from '~/utils/helpersWithoutRouter'
import config from '~/config'

import { withGraphModalActions } from '~/hoc/withGraphModalActions'

import Header from '~/components/Header'

const getEntryData = async (apolloClient, queryParams, domain) => {
  const state = await getFestivalState(apolloClient, queryParams, domain)

  return { festivalState: state }
}

const getSsrCookie = (req, name) => ({ [name]: getCookieAsParsedJson(req, name) })

const ADULT_FESTIVAL_NAME = [null, 'gw', 'chm', 'rw', 'fdw'] // null means RC
const XMAS_REMINDER = []
const DELIVERY_REMINDER = []

export default ({
  access = ACCESS.ALL,
  fullWidth = false,
  withHeader = true,
  namespaces = [],
  contentClassName = '',
  authModalProps = {},
  accessController,
  ssrCookieName,
}) => (WrappedComponent) => {
  @withRouter
  @withGraphModalActions
  class withLayout extends React.Component {

    static async getInitialProps (props) {
      const { query, pathname, req, res, apolloClient } = props

      await basicAuthMiddleware(req, res, {})

      const referer = !!req && req.headers.referer
      const domain = !!req && (req.headers.host || req.headers.origin)

      const festivalName = getFestival({ query, pathname }, domain)

      const namespacesRequired = ['common', 'errors', 'meta', 'languages', ...namespaces]

      const entryData = await getEntryData(apolloClient, query, domain)

      const data = await getFestivalRangeAndPrice(apolloClient, festivalName)
      const { startsAt, endsAt, price } = data || {}

      const err = accessController && await accessController({
        req,
        res,
        apolloClient,
        queryParams: query,
        domain
      })

      const srrCookie = ssrCookieName && getSsrCookie(req, ssrCookieName)

      return {
        namespacesRequired,
        access,
        entryData,
        festivalName,
        startsAt,
        endsAt,
        price,
        srrCookie,
        err,
        referer,
      }
    }

    componentDidMount () {
      this.pornCheck()
      this.xmasCheck()
      this.deliveryCheck()
      this.cookieCheck()
    }

    get isPornCheckNeeded () {
      return ADULT_FESTIVAL_NAME.includes(this.props.festivalName)
    }

    pornCheck = () => {
      const { router, referer } = this.props

      if (this.isPornCheckNeeded) {
        const isAdult = getConsentValue(router, referer, 'adult')

        // TODO: Remove or change id after summer edition of Chefs Menu
        const festivalIds = ['30', '31', '28', '38', '44']
        const listingSelected = router.route === '/restaurants' && festivalIds.find(
          el => el === router.query.festivalFilter
        )

        if (!isAdult && (listingSelected || this.props.festivalName)) {
          this.props.openModal(MODAL_NAMES.CONFIRM_LEGAL_AGE, {
            onReject: () => redirect(this.festival.fb, null)
          })
          updateQueryParam(router.query, router, ['__f'], 'replace')
        }
      }
    }

    xmasCheck = () => {
      const isChristmasSeason = moment().isBefore(moment('31.01.2020', 'DD.MM.YYYY'))
      isChristmasSeason && this.handleSessionStorageModal(MODAL_NAMES.XMAS_REMINDER, XMAS_REMINDER)
    }

    regulationCheck = () => {
      const { entryData, openModal } = this.props
      const { regulationStatus } = entryData
      if (regulationStatus && regulationStatus.regulationStatus !== REGULATION_STATUS.ACCEPTED) {
        openModal(MODAL_NAMES.REGULATION, { status: regulationStatus.regulationStatus })
      }
    }

    deliveryCheck = () => {
      const isPandemicSeason = moment().isBefore(moment('13.04.2020', 'DD.MM.YYYY'))
      isPandemicSeason && this.handleSessionStorageModal(MODAL_NAMES.DELIVERY_REMINDER, DELIVERY_REMINDER)
    }

    cookieCheck = () => {
      const { router, referer } = this.props

      const cookieConsent = getConsentValue(router, referer, 'cookies')
      !cookieConsent && this.props.openModal(MODAL_NAMES.COOKIE)
      updateQueryParam(router.query, router, ['__f'], 'replace')
    }

    handleSessionStorageModal = (modalName, festivalCondition) => {
      const { festivalName, router, openModal } = this.props

      const wasOpened = sessionStorage.getItem(modalName)
      const isConfirmationPage = router.pathname === '/reservation-confirmation'

      if (!wasOpened && !isConfirmationPage && festivalCondition.includes(festivalName)) {
        openModal(modalName)
        sessionStorage.setItem(modalName, 'shown')
      }
    }

    getPageByRoute = (route = '') => route.replace(/\//g, '')

    get festival () {
      const { festivalName } = this.props
      return config(festivalName).festival
    }

    render () {
      const { entryData, router } = this.props
      const route = this.getPageByRoute(router.route)

      return (
        <div className='layout'>
          {withHeader && <Header
            modifiers={route.toLowerCase()}
            isVoucher={entryData.festivalState === FESTIVAL_EDITION_STATES.VOUCHER}
          />}
          <main className={bemCx('layout__content', {}, contentClassName)}>
            <WrappedComponent {...this.props} />
          </main>
        </div>
      )
    }
  }

  return withLayout
}
