import { Link } from '>/server/routes'
import { withRouter } from 'next/router'
import queryString from 'query-string'

import { persistFestivalParam } from '~/utils/helpers'

import config from '~/config'

export const LinkComponent = withRouter(({ route, params, children, disabled, router, prefetch = false, ...props }) => {
  const { query } = router
  const newParams = persistFestivalParam(query, params, route)

  if (disabled) {
    return <div {...props}>{children}</div>
  } else {
    return <Link
      route={route}
      params={newParams}
      // Next.js default behaviour now is to prefetch automatically every page that is linked to
      // If you want to prefetch specyfic page, prefetch prop needs to be undefined
      // In order to disable prefetching, you need to set prefetch the prop to false
      prefetch={prefetch ? undefined : prefetch}
    >
      <a {...props}>{children}</a>
    </Link>
  }
})

export const Redirect = ({ route, params, children, slug, newClient, ...props }) => {
  if (config().enableFestivalParam) {
    // dev and stg
    return <LinkComponent route={route} params={params} children={children} {...props} />
  } else {
    // production
    const baseUrl = newClient ? config().newClubUrl : config().clubUrl
    const param = slug && params && Object.values(params)[0]

    const href = slug
      ? `${baseUrl}/${route}/${param}`
      : queryString.stringifyUrl({
        url: `${baseUrl}/${route}`,
        query: params
      })

    return <a href={href} {...props}>{children}</a>
  }
}

export default LinkComponent
