import config from '~/config'
import queryString from 'query-string'
import omit from 'lodash/omit'
import { FESTIVAL_CODE_TO_NEW_CLIENT_SLUG } from '~/utils/helpers'

export const getIsFestivalPurchaseFlowNewClient = (selectedFestivalCode) =>
  ['bw', 'gw', 'fdw'].includes(selectedFestivalCode)

export const getNewClientListingLink = (festivalCode, redirectParams) =>
  queryString.stringifyUrl({
    url: `${config().newClubUrl}/restaurants/festival/${
      FESTIVAL_CODE_TO_NEW_CLIENT_SLUG[festivalCode]
    }`,
    query: omit(redirectParams, 'festivalFilter')
  })

export const getNewClientEarlyReservationLink = (festivalId, redirectParams) =>
  queryString.stringifyUrl({
    url: `${config().newClubUrl}/early-reservation/${festivalId}`,
    query: omit(redirectParams, 'festivalFilter')
  })

export const getNewClientVoucherLink = (festivalId, redirectParams) =>
  queryString.stringifyUrl({
    url: `${config().newClubUrl}/voucher-details/${festivalId}`,
    query: omit(redirectParams, 'festivalFilter')
  })
