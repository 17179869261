import { FESTIVAL } from './festivalTypes'
import { processEnv } from '~/utils/helpers'

export const DEFAULT_FESTIVAL = null

export default (festivalName) => {
  const festival = FESTIVAL(festivalName)

  const def = {
    festival,
    enableAnalytics: false,
    enableFestivalParam: true,
    imgproxyKey: processEnv.IMGPROXY_KEY || '',
    imgproxySalt: processEnv.IMGPROXY_SALT || '',
    imgproxyUrl: processEnv.IMGPROXY_URL,
    facebookAppId: 331488624530952,
    appleServiceId: 'pl.restaurantweek.restaurantclub.service.dev',
    apiUrl: processEnv.API_URL || 'https://api.restaurantclub.pl',
    googleAPIKey: processEnv.GOOGLE_API_KEY,
    sentryDsn: processEnv.SENTRY_DSN,
    sentryRelease: processEnv.SENTRY_RELEASE || '',
    sentryEnv: processEnv.NEXT_ENV || 'unknown',
    mapboxToken: processEnv.MAPBOX_TOKEN,
    gtm_key: 'GTM-TBKQM48',
    clubUrl: processEnv.CLUB_URL || 'https://restaurantclub.pl',
    newClubUrl: processEnv.NEW_CLUB_URL || processEnv.CLUB_URL || 'https://restaurantclub.pl',
    enableFestivalFilter: true,
    defaultFestivalId: '1',
    promotedFestivals: { fdw: '2' },
    salt: processEnv.SALT,
    klarna: {
      sdk: 'https://eu-library.playground.klarnaservices.com/lib.js',
      client_id: '462d7378-512a-5d05-9ddf-2dec50435c7a',
      enableBranding: false
    },
    enableFocusChat: false,
    commonNinjaWidgetIds: processEnv.COMMONNINJA_WIDGETS_IDS?.split(';') || [],
    chatbotHash: processEnv.CHATBOT_HASH || '',
    metaPixelId: processEnv.META_PIXEL_ID || ''
  }
  const development = {
    ...def,
    enableAnalytics: true,
    enableFocusChat: false,
    enableFestivalParam: false,
  }
  const review = {
    ...def,
    enableFocusChat: true,
  }
  const staging = {
    ...def,
    enableAnalytics: true,
    enableFocusChat: true,
    enableFestivalParam: false,
  }
  const demo = {
    ...def,
    appleServiceId: processEnv.APPLE_SERVICE_ID || 'pl.restaurantweek.restaurantclub.service.demo',
    enableFestivalFilter: false,
    defaultFestivalId: '1',
    promotedFestivals: { fdw: '2' },
    enableFocusChat: true,
  }
  const production = {
    ...def,
    enableAnalytics: true,
    enableFestivalParam: false,
    facebookAppId: 160962760905642,
    appleServiceId: processEnv.APPLE_SERVICE_ID || 'pl.restaurantweek.restaurantclub.service',
    enableFestivalFilter: false,
    defaultFestivalId: '24',
    promotedFestivals: { fdw: '17' },
    klarna: {
      sdk: 'https://eu-library.klarnaservices.com/lib.js',
      client_id: '88abe382-8a1a-5f94-bd0f-f75b323fbf26',
      enableBranding: false
    }
  }
  const config = {
    development,
    review,
    staging,
    demo,
    production
  }
  const testing = {
    ...development,
    festival: FESTIVAL(DEFAULT_FESTIVAL)
  }
  return config[processEnv.NEXT_ENV] || testing
}
