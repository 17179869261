import rc from './festivals/rc'
import rw from './festivals/rw'
import fdw from './festivals/fdw'
import rwx from './festivals/rwx'
import korw from './festivals/korw'
import bfw from './festivals/bfw'
import dw from './festivals/dw'
import cf from './festivals/cf'
import rctjz from './festivals/rctjz'
import neon from './festivals/neon'
import chm from './festivals/chm'
import gw from './festivals/gw'
import cc from './festivals/cc'
import bw from './festivals/bw'
import pw from './festivals/pw'

const FESTIVAL_TYPES = {
  rc,
  rw,
  fdw,
  rwx,
  korw,
  bfw,
  dw,
  cf,
  rctjz,
  neon,
  gw,
  chm,
  cc,
  bw,
  pw,
}

const FESTIVAL = function (festivalName) {
  const name = festivalName || 'rc'
  return FESTIVAL_TYPES[name] || {}
}

module.exports = {
  FESTIVAL,
  FESTIVAL_TYPES
}
