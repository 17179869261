const dynamicRoutes = [
  {
    name: 'restaurant-profile',
    pattern: '/restaurant-profile/:restaurant_id-:restaurant_slug',
    page: 'restaurant-profile'
  },
  { name: 'side-event', pattern: '/side-event/:event_id', page: 'side-event' },
  { name: 'reset-password', pattern: '/reset-password/:token', page: 'reset-password' },
  { name: 'register-confirmation', pattern: '/register-confirmation/:token', page: 'register-confirmation' },
  { name: 'authorization', pattern: '/authorization/:type', page: 'authorization' },
  { name: 'confirm', pattern: '/confirm/:code', page: 'reservation-confirmation' },
  { name: 'regulation', pattern: '/regulation/:id', page: 'regulation' },
  { name: 'cancellation', pattern: '/c/:token', page: 'cancellation' },
  { name: 'reserve', pattern: '/reserve/:hash', page: 'reserve' }
]

module.exports = dynamicRoutes
