import React from 'react'

import MobileMenuView from './MobileMenuView'

import { toggleBodyScroll, scrollTo, formatLocationQuery, handleSponsorsLink } from '~/utils/helpers'
import { CAREER_LINK } from '~/utils/consts'

export default class MobileMenuContainer extends React.PureComponent {

  componentWillMount () {
    setTimeout(() => this.props.handleState('animation', 'is-open'), 0)
  }

  handleScrollTo = (sectionId, sectionOffset, e) => {
    this.props.handleState('isMobileMenuOpen', !this.props.isMobileMenuOpen)
    toggleBodyScroll(!this.props.isMobileMenuOpen)
    scrollTo(sectionId, { duration: 350, offset: -sectionOffset })(e)
  }

  handleLinkClick = () => {
    this.props.handleState('isMobileMenuOpen', !this.props.isMobileMenuOpen)
    toggleBodyScroll(false)
  }

  get listingLinkParams () {
    const { festivalId } = this.props
    const params = { location: this.locationStr }
    if (festivalId) params.festivalFilter = festivalId
    return params
  }

  get locationStr () {
    return formatLocationQuery(this.props.location)
  }

  get voucherRedirectParams () {
    const { festivalId } = this.props
    return { festivalId }
  }

  get partnersLink () {
    const { selectedFestivalCode, isListingRoute } = this.props
    return handleSponsorsLink({ selectedFestivalCode, isListingRoute, handleScrollTo: this.handleScrollTo })
  }

  get careersLink () {
    return CAREER_LINK
  }

  render () {
    const { animation, linkCheck, festivalId, isBlack } = this.props

    return (
      <MobileMenuView
        {...this.state}
        {...this.props}
        festivalId={festivalId}
        animation={animation}
        linkCheck={linkCheck}
        locationStr={this.locationStr}
        voucherRedirectParams={this.voucherRedirectParams}
        listingLinkParams={this.listingLinkParams}
        handleScrollTo={this.handleScrollTo}
        handleLinkClick={this.handleLinkClick}
        partnersLink={this.partnersLink}
        careersLink={this.careersLink}
        isBlack={isBlack}
      />
    )
  }
}
