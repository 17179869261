import { TAG_TO_OMIT, TAGS, HEADER_LINK_NAMES } from '~/utils/consts'

export default {
  name: 'GinWeek',
  id: 'festival-gw',

  url: 'ginweek.pl',
  query: 'gw',

  fb: 'https://www.facebook.com/restaurantclubpl',
  instagram: 'https://www.instagram.com/explore/tags/restaurantweekpolska',
  email: 'info@restaurantclub.pl',
  showAd: true,
  chatbotEnabled: true,

  faq: {
    offset: {
      mobile: 30,
      tablet: 30,
      desktop: 66,
      intersection: 28
    }
  },

  tagsToOmit: [...TAG_TO_OMIT, TAGS.DELIVERY_REGION],
  headerLinks: [HEADER_LINK_NAMES.RESTAURANTS, HEADER_LINK_NAMES.FAQ, HEADER_LINK_NAMES.PARTNERS]
}
