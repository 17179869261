import gql from 'graphql-tag'
import { Mutation } from '@apollo/client/react/components'
import UserMenuContainer from './UserMenuContainer'
import { useApolloClient } from '@apollo/client'
import * as Sentry from '@sentry/node'

const SIGN_OUT = gql`
  mutation SignOut {
    signOut
  }
`

const UserMenu = (props) => {
  const client = useApolloClient()
  return <Mutation
    mutation={SIGN_OUT}
    onCompleted={() => Sentry.configureScope(scope => scope.setUser(null))}
  >
    {(signOut, { loading }) => {
      return (
        <UserMenuContainer
          signOut={signOut}
          loading={loading}
          client={client}
          {...props}
        />
      )
    }}
  </Mutation>
}

export default UserMenu
