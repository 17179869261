import { TAG_TO_OMIT, TAGS, HEADER_LINK_NAMES } from '~/utils/consts'

export default {
  name: 'Fine Dining Week',
  id: 'festival-fdw',

  url: 'finediningweek.pl',
  query: 'fdw',

  fb: 'https://www.facebook.com/FineDiningWeek',
  instagram: 'https://www.instagram.com/explore/tags/restaurantweekpolska',
  email: 'info@finediningweek.pl',
  showAd: true,
  chatbotEnabled: true,

  faq: {
    offset: {
      mobile: 22,
      tablet: 28,
      desktop: 40,
      intersection: 26,
    }
  },

  tagsToOmit: [...TAG_TO_OMIT, TAGS.DELIVERY_REGION],
  headerLinks: [
    HEADER_LINK_NAMES.RESTAURANTS,
    HEADER_LINK_NAMES.FAQ,
    HEADER_LINK_NAMES.PARTNERS,
    HEADER_LINK_NAMES.GIFT_EVENTS,
    HEADER_LINK_NAMES.SIDE_EVENTS,
    HEADER_LINK_NAMES.BLOG
  ]
}
