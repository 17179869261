import gql from 'graphql-tag'

export const GET_TAGS_FRAGMENT = gql`
  fragment tags on Tag {
    id
    name
    type
    position
  }
`

export const GET_PHOTOS_FRAGMENT = gql`
  fragment photos on ImageFile {
    id
    fileUrl(version: PREVIEW)
    position
  }
`

export const GET_CROSSSELLS_FRAGMENT = gql`
  fragment crossSells on CrossSell {
    id
    name
    image {
      id
      fileUrl
    }
    price
  }
`

export const GET_RESERVATION_CROSS_SELL = gql`
  fragment reservationCrossSells on reservationCrossSells {
    id
    quantity
    crossSell {
      id
      name
      price
    }
  }
`

export const GET_RESERVATION_CROSSELLS_FRAGMENT = gql`
  fragment reservationWithCrossSells on Reservation {
    id
    code
    price
    ...reservationCrossSells
  }
  ${GET_RESERVATION_CROSS_SELL}
`

export const GET_MENUS_FRAGMENT = gql`
  fragment menus on Menu {
    label
    positions {
      name
      content
    }
  }
`

export const GET_FER_FRAGMENT = gql`
  fragment fer on FestivalEditionRestaurant {
    id
    menuA
    menuB
    labelA
    labelB
    festivalEdition {
      id
      price
      minPeopleCount
      crossSells {
        id
        adult
        description
        image {
          id
          fileUrl
        }
        name
        price
      }
    }
    crossSells {
      id
      name
      image {
        id
        fileUrl
      }
      price
    }
    restaurant {
      id
      address
      latitude
      longitude
      chef
      description
      establishmentYear
      name
      phoneNumber
      facebookUrl
      instagramUrl
      rating
      openingHours {
        day
        from
        to
      }
      tags {
        id
        icon
        name
        type
      }
      imageFiles {
        id
        original
        fileUrl(version: ORIGINAL)
        position
      }
    }
  }
`

export const GET_RESTAURANT_FRAGMENT = gql`
  fragment fullResturant on Restaurant {
    id
    address
    latitude
    longitude
    chef
    restaurator
    description
    establishmentYear
    name
    phoneNumber
    facebookUrl
    instagramUrl
    # rating
    menuFileUrl
    restaurantDeliveryZones {
      label
      deliveryRadius
      price
    }
    openingHours {
      day
      from
      to
    }
    tags {
      id
      icon
      name
      type
    }
    imageFiles {
      id
      original
      fileUrl(version: ORIGINAL)
      position
    }
    zone {
      id
      name
      region {
        id
        name
      }
    }
  }
`
