import { ApolloClient, HttpLink, ApolloLink } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import fetch from 'isomorphic-unfetch'
import ApolloLinkTimeout from 'apollo-link-timeout'
import { onError } from '@apollo/client/link/error'
import { SentryLink } from 'apollo-link-sentry'
import get from 'lodash/get'

import { getFestival, getGraphQLApiUrl, hasWindow } from '~/utils/helpers'
import localInitialState from '~/utils/graph-initial-state'
import { GET_LOCATION } from '~/utils/queries.gql'

let apolloClient = null

function create (initialState, ctx = {}) {

  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  const cache = new InMemoryCache({
    typePolicies: {
      Slot: {
        keyFields: ['date', 'slot', 'reservable', ['__typename', 'id']]
      },
      ReservationClosingRule: {
        keyFields: ['daysOffset', 'minutesSinceMidnight']
      },
      Reservation: {
        fields: {
          priceValues: {
            merge (existing, incoming) {
              return { ...existing, ...incoming }
            },
          }
        }
      },
    }
  }).restore(initialState || {})

  const writeInitialLocalData = () => {
    cache.writeQuery({
      query: GET_LOCATION,
      data: localInitialState.data,
    })
  }

  writeInitialLocalData()

  if (process.browser) {
    // hydrate client-side cache with SSR data
    cache.restore(window.__APOLLO_STATE__)
  }

  const domainName = hasWindow() ? window.location.host : (ctx.req && ctx.req.headers.host)
  const { fetchOptions } = ctx

  // for production only, dev and stg will use API_URL env variable
  const festivalName = getFestival({}, domainName)

  const errorLink = onError(({ networkError }) => {
    if (networkError) {
      // Check if error response is JSON
      try {
        JSON.parse(networkError.bodyText)
      } catch (e) {
        // If not replace parsing error message with real one
        // networkError.message = networkError.bodyText
        networkError.message = networkError.bodyText
      }
    }
  })

  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([
      new SentryLink({
        setTransaction: false,
        setFingerprint: true,
        breadcrumb: {
          enable: true,
          includeQuery: true,
          includeVariables: true,
          includeResponse: true,
          includeError: true,
          includeContextKeys: ['headers'],
        }
      }),
      errorLink,
      new ApolloLink((operation, forward) => {
        // operation.setContext({
        //   headers: {
        //     'X-language': hasWindow() && localStorage ? localStorage.getItem('language') : undefined,
        //   }
        // })

        return forward(operation).map(result => {
          const context = operation.getContext()
          const {
            response: { headers },
          } = context
          if (headers) {
            const requestId = headers.get('x-request-id')
            const timestamp = headers.get('x-request-timestamp')
            const isReservationRequest = get(result, 'data.reservation')

            result.headers = {
              'x-request-id': requestId,
            }

            timestamp && hasWindow() && isReservationRequest && localStorage.setItem('timestamp', timestamp)
          }

          return result
        })
      }),
      new ApolloLinkTimeout(parseInt(process.env.TIMEOUT || '30000', 10)),
      new HttpLink({
        uri: getGraphQLApiUrl(festivalName), // Server URL (must be absolute)
        credentials: 'include', // Additional fetch() options like `credentials` or `headers`
        // Use fetch() polyfill on the server
        fetch: typeof window === 'undefined' && fetch,
        headers: {
          ...ctx.req && ctx.req.header && { cookie: ctx.req.header('Cookie') },
        },
        fetchOptions,
      })
    ]),
    cache,
  })
}

export default function initApollo (initialState, ctx) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    let fetchOptions = {}
    // If you are using a https_proxy, add fetchOptions with 'https-proxy-agent' agent instance
    // 'https-proxy-agent' is required here because it's a sever-side only module
    if (process.env.HTTPS_PROXY && process.env.NEXT_ENV === 'development') {
      const HttpsProxyAgent = require('https-proxy-agent')
      fetchOptions = {
        agent: new HttpsProxyAgent(process.env.HTTPS_PROXY)
      }
    }
    return create(initialState,
      {
        ...ctx,
        fetchOptions
      })
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, ctx)
  }

  return apolloClient
}
