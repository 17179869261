import { getStaticFilePath, getRasterImagePath, isSVG } from '~/utils/helpers'

export default ({
  src,
  srcMobile,
  urlSrc,
  pictureClassName,
  className,
  width = '992',
  children,
  blankMediaQuery,
  alt,
  href
}) => {
  const isSVGFile = isSVG(src)
  const blankSource = blankMediaQuery && getStaticFilePath('assets/blank.png')
  const source = src && getStaticFilePath(src)
  const sourceMobile = srcMobile && getStaticFilePath(srcMobile)
  const hrefValue = href || null
  const PictureWrapper = hrefValue ? `a` : `div`

  const enableBlankSource = !!blankMediaQuery
  const enableBigSource = !isSVGFile
  const enableMobileSource = !blankMediaQuery && !urlSrc && !isSVGFile && srcMobile

  return (
    <picture className={pictureClassName}>
      <PictureWrapper href={hrefValue} className='picture-overlay'>
        {/* source if file isn't displayed for the specified width (one pixel file) */}
        {enableBlankSource && <source
          srcSet={blankSource}
          media={blankMediaQuery}
        />}

        {/* source for a big screen */}
        {enableBigSource && <source
          srcSet={urlSrc || `${source} 1x, ${getRasterImagePath(src)} 2x`}
          media={blankMediaQuery ? undefined : `(min-width: ${width}px)`}
        />}

        {/* source for a small screen; specific file depending on screen resolution */}
        {enableMobileSource && <source
          srcSet={`${sourceMobile} 1x, ${getRasterImagePath(srcMobile)} 2x`}
        />}

        {/* fallback if no condition is met */}
        {children || <img
          src={source || urlSrc}
          className={className}
          alt={alt}
        />}
      </PictureWrapper>
    </picture>
  )
}
