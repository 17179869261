import dynamic from 'next/dynamic'

import { MODAL_NAMES } from '~/utils/consts'

const AuthenticateModal = dynamic(() => import('~/components/modals/AuthenticateModal'))
const ConfirmLegalAgeModal = dynamic(() => import('~/components/modals/ConfirmLegalAgeModal'))
const XmasReminderModal = dynamic(() => import('~/components/modals/XmasReminderModal'))
const DeliveryReminderModal = dynamic(() => import('~/components/modals/DeliveryReminderModal'))
const CookieModal = dynamic(() => import('~/components/modals/CookieModal'))
const RegulationModal = dynamic(() => import('~/components/modals/RegulationModal'))
const NewsletterModal = dynamic(() => import('~/components/modals/NewsletterModal'))
const ExtraGuestModal = dynamic(() => import('~/components/modals/ExtraGuestModal'))

const {
  AUTHENTICATE,
  CONFIRM_LEGAL_AGE,
  COOKIE,
  CROSS_SELL_DIFF,
  DELIVERY_REMINDER,
  EVALUATION,
  EXPIRED_ORDER,
  IMPROPER_USER,
  INVALID_RESERVATION,
  NEWSLETTER,
  REGULATION,
  XMAS_REMINDER,
  RESERVATION_UPDATE_UNAVAILABLE,
  EXTRA_GUEST
} = MODAL_NAMES

export const uncloseables = [
  CONFIRM_LEGAL_AGE,
  COOKIE,
  CROSS_SELL_DIFF,
  EXPIRED_ORDER,
  EVALUATION,
  IMPROPER_USER,
  INVALID_RESERVATION,
  RESERVATION_UPDATE_UNAVAILABLE,
]

export const commonModals = {
  [AUTHENTICATE]: ({ closeThisModal }) => <AuthenticateModal closeThisModal={closeThisModal} />,
  [CONFIRM_LEGAL_AGE]: ({ closeThisModal }) =>
    <ConfirmLegalAgeModal type='generic' closeThisModal={closeThisModal} />,
  [COOKIE]: ({ closeThisModal }) => <CookieModal closeThisModal={closeThisModal} />,
  [XMAS_REMINDER]: () => <XmasReminderModal />,
  [DELIVERY_REMINDER]: () => <DeliveryReminderModal />,
  [REGULATION]: ({ closeThisModal }) => <RegulationModal closeThisModal={closeThisModal} />,
  [NEWSLETTER]: ({ closeThisModal }) => <NewsletterModal closeThisModal={closeThisModal} />,
  [EXTRA_GUEST]: ({ closeThisModal }) => <ExtraGuestModal closeThisModal={closeThisModal} />
}

// URL-based modals
export const urlModal = {}
// urlModal = { [param]: [modalName] }

// param – query param that opens the modal
// modalName – name of modal related to the param

// e.g.
// 'covid': MODAL_NAMES.COVID_19,
