export const MIN_NUMBER_OF_ATTENDEES = 1
export const MAX_NUMBER_OF_ATTENDEES = 8
export const INITIAL_NUMBER_OF_ATTENDEES = 2

export const START_HOUR = 0
export const END_HOUR = 1425
export const SLOT_DURATION = 15
export const RESERVATION_OFFSET = 120
export const EXPIRATION_TIME_FER = 900 // 15min
export const EXPIRATION_TIME_DAILY = 300 // 5min

export const DEFAULT_LOCATION = { label: 'Warszawa', value: 5 }

export const VOIVODESHIP_CAPITAL = {
  mazowieckie: 'Warszawa',
  podlaskie: 'Białystok',
  lubelskie: 'Lublin',
  'warmińsko-mazurskie': 'Olsztyn',
  pomorskie: 'Gdańsk',
  zachodniopomorskie: 'Szczecin',
  lubuskie: 'Zielona Góra',
  wielkopolskie: 'Poznań',
  dolnośląskie: 'Wrocław',
  śląskie: 'Katowice',
  opolskie: 'Opole',
  małopolskie: 'Kraków',
  lódzkie: 'Łódź',
  'kujawsko-pomorskie': 'Bydgoszcz',
  świętokrzyskie: 'Kielce',
  podkarpackie: 'Rzeszów',
}

export const ACCESS = {
  ALL: 'all',
  LOGGED: 'logged',
}

export const FILTER_INITIAL_VALUES = {
  keyword: '',
  people: MIN_NUMBER_OF_ATTENDEES,
  date_picker: {
    date_start: null,
    date_end: null,
    dates: {},
  },
  festivalFilter: []
}

export const TAGS = {
  ATMOSPHERE: 'Atmosphere',
  CATEGORY: 'Category',
  CUISINE: 'Cuisine',
  DIET: 'Diet',
  DISH: 'Dish',
  ESTABLISHMENT_TYPE: 'EstablishmentType',
  OCCASION: 'Occasion',
  OTHER: 'Other',
  PRICE: 'Price',
  RESERVATION_TYPE: 'ReservationType',
  DELIVERY_REGION: 'DeliveryRegion',
  COLLECTION: 'Collection',
  FESTIVAL: 'festivalFilter' // in fact it is NOT a tag
}

export const EVENTS_TAGS = [
  TAGS.ATMOSPHERE,
  TAGS.CATEGORY,
  TAGS.CUISINE,
  TAGS.DIET,
  TAGS.DISH,
  TAGS.ESTABLISHMENT_TYPE,
  TAGS.OCCASION,
  TAGS.PRICE,
  TAGS.COLLECTION
]

export const TAGS_SORT_PATTERN = [
  TAGS.COLLECTION,
  TAGS.DELIVERY_REGION,
  TAGS.ESTABLISHMENT_TYPE,
  TAGS.RESERVATION_TYPE,
  TAGS.CUISINE,
  TAGS.DISH,
  TAGS.DIET,
  TAGS.CATEGORY,
  TAGS.PRICE,
  TAGS.OCCASION,
  TAGS.ATMOSPHERE,
  TAGS.OTHER,
]

export const TAG_FILTERS = Object.values(TAGS).map(tag => tag !== TAGS.FESTIVAL && tag)

export const TAG_TO_OMIT = [
  TAGS.PRICE,
  TAGS.RESERVATION_TYPE,
  TAGS.DELIVERY_REGION,
]

export const FILTERS_TO_OMIT = [
  'location',
  'featured',
  'hours',
  'dates',
  'festival',
  TAGS.FESTIVAL,
  ...TAG_FILTERS
]

export const MAX_CHECKBOX_FILTERS_NUMBER = 4
export const EXPLANATION_SECTION_TOP_MARGIN = 150

export const DEFAULT_TILES = Array(4).fill({ hour: '' })

export const START_DATE = 'startDate'
export const END_DATE = 'endDate'

export const MODAL_NAMES = {
  CONFIRM_LEGAL_AGE: 'confirm-legal-age',
  NEWSLETTER: 'newsletter',
  NEWSLETTER_EXIT: 'newsletter-exit',
  USER_BILLING: 'user-billing-data',
  EXPIRED_ORDER: 'expired-order',
  EVALUATION: 'evaluation',
  AUTHENTICATE: 'authenticate',
  CANCEL_RESERVATION: 'cancel-reservation',
  RESTAURANT: 'restaurant',
  CROSS_SELL_DIFF: 'cross-sells-diff',
  INVALID_RESERVATION: 'invalid-reservation',
  XMAS_REMINDER: 'xmas-reminder',
  DELIVERY_REMINDER: 'delivery-reminder',
  COVID_19: 'covid-19',
  IMPROPER_USER: 'improper-user',
  COOKIE: 'cookie',
  COKE: 'coke',
  REGULATION: 'regulation',
  RESERVATION_UPDATE_UNAVAILABLE: 'reservation-update-unavailable',
  MARKETING_CONSENT: 'marketing-consent',
  EXTRA_GUEST: 'extra-guest'
}

export const AUTH_TYPE = {
  FESTIVAL: 'festival-reservation',
  VOUCHER: 'voucher-purchase',
  EVALUATION: 'evaluation',
}

export const FESTIVAL_EDITION_STATES = {
  VOUCHER: 'VOUCHER',
  PRESALE: 'PRESALE',
  SALE: 'SALE',
  UNAVAILABLE: 'UNAVAILABLE'
}

export const SPONSOR = {
  MAIN: 'MAIN',
  PARTNER: 'PARTNER',
  PATRON: 'PATRON',
  REGIONAL: 'REGIONAL',
  REGULAR: 'REGULAR',
  PLEBISCITE_PARTNER: 'PLEBISCITE_PARTNER'
}

export const SLOTS_FETCH_INTERVAL = 300000 // = 5min

export const RESERVABLE_TYPES = {
  EVENT: 'Event',
  DAILY: 'Daily',
  FER: 'FestivalEditionRestaurant'
}

export const RESTAURANTS_PAGE_SIZE = 9

export const RESERVATION_STATUS = {
  CANCELED: 'CANCELED'
}

export const RESERVATION_TYPE = {
  EVENT: 'event',
  VOUCHER: 'voucher',
  FER: 'fer',
  GIFT: 'gift',
  D2D: 'd2d'
}

export const DISCOUNT_TYPE = {
  PERCENT: 'PERCENT'
}

export const MOBILE_APP_LINK = {
  ANDROID: 'https://play.google.com/store/apps/details?id=pl.restaurantweek.restaurantclub',
  IOS: 'https://itunes.apple.com/app/id1490565530'
}

export const DEFAULT_HEADER_PAGES = [
  '/restaurant-profile',
  '/gift-events-listing',
  '/side-events-listing',
  '/chefsmenu',
  '/side-event',
  '/group-reservations',
  '/my-reservations',
  '/faq',
  '/order/failure',
  '/reservation-edit',
  '/regulations',
  '/become-partner',
  '/festival/rw',
  '/festival/rw/faq',
  '/festival/gw',
  '/festival/gw/faq',
  '/festival/pw',
  '/festival/pw/faq',
  '/festival/cc',
  '/festival/cc/faq',
  '/festival/bw',
  '/festival/bw/faq',
  '/menus',
  '/klarna-help'
]

export const HEADER_LINK_NAMES = {
  RESTAURANTS: 'restaurants',
  ABOUT: 'about',
  FAQ: 'faq',
  PARTNERS: 'partners',
  VOUCHER: 'voucher',
  SIDE_EVENTS: 'side-events',
  GIFT_EVENTS: 'gift-events',
  GROUP_RESERVATIONS: 'group-reservations',
  JOIN_US: 'join-us',
  CAREERS: 'careers',
  BLOG: 'weekly'
}

export const COMMON_HEADER_LINKS = [
  HEADER_LINK_NAMES.RESTAURANTS,
  // HEADER_LINK_NAMES.ABOUT,
  HEADER_LINK_NAMES.FAQ,
  HEADER_LINK_NAMES.PARTNERS,
  HEADER_LINK_NAMES.CAREERS,
  HEADER_LINK_NAMES.VOUCHER,
  HEADER_LINK_NAMES.GIFT_EVENTS,
  HEADER_LINK_NAMES.BLOG
]

export const ALPHABET = 'ABCDEFGHIJKLMN'

export const REGULATION_TYPES = {
  MAIN_REGULATION: 'MAIN_REGULATION',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  REGULATION: 'REGULATION',
  OTHER_REGULATION: 'OTHER_REGULATION'
}

export const MAPBOX_LINK = 'https://osm.restaurantclub.pl/styles/v1/restaurantweek/ck2q4mbc00dem1cp791flcoda/tiles/{z}/{x}/{y}?access_token='

export const DESCRIPTION_LIMIT = 155

export const WEEK = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

export const REGULATION_STATUS = {
  ACCEPTED: 'ACCEPTED',
  EXPIRED: 'EXPIRED',
  NOT_ACCEPTED: 'NOT_ACCEPTED'
}

export const DELIVERY_TYPE = {
  ONSITE: 'ONSITE',
  TAKE_AWAY: 'TAKE_AWAY',
  DELIVERY: 'DELIVERY'
}

export const REFERER_WHITELIST = [
  'https://restaurantweek.pl',
  'https://finediningweek.pl',
  'https://beerfoodweek.pl',
  'https://cocktailfestival.pl',
  'https://koneser.restaurantweek.pl',
  'https://swieta.restaurantweek.pl',
  'https://festivals-stage.rclub.dev',
  'https://breakfastweek.pl',
]

export const VISA_LINK = {
  rw: 'https://www.visa.pl/pl_pl/visa-benefit/visa-restaurant-week/159754',
  bw: 'https://www.visa.pl/pl_pl/visa-benefit/visa-breakfast-week/159758',
  fdw: 'https://www.visa.pl/pl_pl/visa-benefit/visa-fine-dining-week/154238'
}

export const CAREER_LINK = 'https://restaurantclub.freshteam.com/jobs'
