import { COMMON_HEADER_LINKS, HEADER_LINK_NAMES } from '~/utils/consts'

export default {
  name: 'RestaurantClub',
  id: 'festival-rc',

  url: 'https://restaurantclub.pl',
  query: '',

  fb: 'https://www.facebook.com/RestaurantWeekPolska',
  instagram: 'https://www.instagram.com/explore/tags/restaurantclubpolska',
  email: 'info@restaurantclub.pl',
  showAd: true,

  faq: {
    offset: {
      mobile: 30,
      tablet: 30,
      desktop: 66,
      intersection: 28
    },
  },

  headerLinks: [...COMMON_HEADER_LINKS, HEADER_LINK_NAMES.SIDE_EVENTS]
}
