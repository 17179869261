import React from 'react'
import bemCx from 'bem-modifiers'
import queryString from 'query-string'

import Link from '~/components/Link'

import config from '~/config'

export const Button = React.forwardRef(({
  disabled,
  loading,
  className,
  modifiers = 'primary',
  label,
  type = 'button',
  Icon,
  disableSpaceBetween,
  onClick,
}, ref) => {
  const isLabelArray = Array.isArray(label)
  const spaceBetween = !disableSpaceBetween && isLabelArray

  const handleClick = e => {
    onClick && onClick(e)
  }

  return (
    <button
      type={type}
      disabled={disabled}
      className={bemCx('button', [modifiers, { 'space-between': spaceBetween }], className)}
      onClick={handleClick}
      ref={ref}
    >
      {loading ?
        <span className='button__loader' />
        :
        <React.Fragment>
          {Icon &&
            <span className='button__icon'>
              <Icon />
            </span>
          }
          {isLabelArray ?
            label.map((item, i) => <span key={i} className='button__label'>{item}</span>)
            :
            <span className='button__label'>{label}</span>
          }
        </React.Fragment>
      }
    </button>
  )
})

export const LinkButton = ({
  disabled,
  loading,
  className,
  modifiers = 'primary',
  label,
  route,
  params,
  onClick,
  prefetch,
  disableSpaceBetween,
  iconLeft,
  iconRight,
  ...otherProps
}) => {
  const isLabelArray = Array.isArray(label)
  const spaceBetween = !disableSpaceBetween && isLabelArray

  const classNameString = bemCx('button', [modifiers, { 'space-between': spaceBetween }, { disabled }], className)

  return disabled ? (
    <div className={classNameString}>
      {loading ?
        <span className='button__loader' />
        :
        <span className='button__label'>
          {label}
        </span>
      }
    </div>
  ) : (
    <Link
      className={classNameString}
      route={route}
      params={params}
      onClick={onClick}
      disabled={disabled}
      prefetch={prefetch}
      {...otherProps}
    >
      {iconLeft && <i className={`button__icon i-${iconLeft}`} />}
      {isLabelArray ?
        label.map((item, i) => <span key={i} className='button__label'>{item}</span>)
        :
        <span className='button__label'>{label}</span>
      }
      {iconRight && <i className={`button__icon i-${iconRight}`} />}
    </Link>
  )
}

export const AnchorButton = ({
  disabled,
  loading,
  className,
  modifiers = 'primary',
  label,
  icon,
  iconRight,
  disableSpaceBetween,
  ...otherProps
}) => {
  const isLabelArray = Array.isArray(label)
  const spaceBetween = !disableSpaceBetween && isLabelArray
  const classNameString = bemCx('button', modifiers, className)

  return disabled ? (
    <div className={classNameString}>
      <span className='button__label'>
        {label}
      </span>
    </div>
  ) : (
    <a
      className={classNameString}
      {...otherProps}
    >
      {loading
        ? <span className='button__loader' />
        : <div className={bemCx('button__content', { 'spread': icon || spaceBetween })}>
          {isLabelArray
            ? label.map((item, i) => <span key={i} className='button__label'>{item}</span>)
            : <span className='button__label'>{label}</span>
          }
          {icon && <span className={`button__icon ${icon}`} />}
        </div>
      }
      {iconRight && <i className={`button__icon i-${iconRight}`} />}
    </a>
  )
}

export const RedirectButton = ({ route, params, ...props }) => {
  if (config().enableFestivalParam) {
    // dev and stg
    return <LinkButton route={route} params={params} {...props} />
  } else {
    // production
    const href = queryString.stringifyUrl({
      url: `${config().clubUrl}/${route}`,
      query: params
    })

    return <AnchorButton href={href} {...props} />
  }
}
