import React from 'react'
import { isEmpty } from 'ramda'
import bemCx from 'bem-modifiers'
import { withNamespaces } from '>/i18n'

import withUser from '~/hoc/withUser/withUser'
import { withGraphModalActions } from '~/hoc/withGraphModalActions'

import { toggleBodyScroll, hasWindow } from '~/utils/helpers'
import { MODAL_NAMES } from '~/utils/consts'

import UserMenu from '~/components/UserMenu/UserMenuGql'
import MobileMenu from '~/components/MobileMenu/MobileMenuGql'
import DropdownTransitionWrapper from '~/components/DropdownTransitionWrapper'
import { GwLogo, Logo } from '~/components/DesktopNavigation/DesktopNavigationView'
import MobileAdvertisement from '~/components/MobileAdvertisement'
import withFestivalContext from '../hoc/withFestivalContext'
import { getIsFestivalPurchaseFlowNewClient } from '~/utils/newClient'
import { getNewClientListingLink } from '../utils/newClient'

const MENU_MOBILE_ANIMATION_DURATION = 300

const Hamburger = ({ active, onClick }) => (
  <button
    type='button'
    className={bemCx('hamburger', 'slider', { 'is-active': active })}
    onClick={onClick}
  >
    <span className='hamburger-box'>
      <span className='hamburger-inner' />
    </span>
  </button>
)

@withUser
@withGraphModalActions
@withFestivalContext
@withNamespaces(['common'])
export default class MobileNavigation extends React.Component {
  state = {
    isAdEnabled: true,
    isMenuUserVisible: false,
    isMobileMenuOpen: false,
    animation: null,
    previousScrollY: 0,
    previousHeaderPosition: null,
  }

  componentWillMount () {
    const headerElement = hasWindow() && document.querySelector('.header')
    const headerElementPosition = headerElement && window.getComputedStyle(headerElement).getPropertyValue('position')

    this.setState({
      previousScrollY: hasWindow() && window.scrollY,
      previousHeaderPosition: headerElementPosition,
    })
  }

  componentDidMount () {
    window.addEventListener('resize', this.closeMobileMenuWhenResizedToDesktop)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.closeMobileMenuWhenResizedToDesktop)
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const { isMobileMenuOpen, previousScrollY } = this.state
    const mobileNavigation = hasWindow() && document.querySelector('.mobile-navigation__container')
    const mobileNavigationHeight = 78

    if (isMobileMenuOpen) return

    if (window.scrollY > previousScrollY) {

      if (window.scrollY > mobileNavigationHeight) {
        mobileNavigation.style.cssText = 'position:fixed; top:-78px; left:0;'
      }

    } else if ((window.scrollY < previousScrollY)) {
      if (window.scrollY <= 0) {
        mobileNavigation.style.cssText = 'position:static'
      } else if (window.scrollY >= mobileNavigationHeight) {
        mobileNavigation.style.cssText = 'position:fixed; top:0; left:0;'
      }
    }
    this.setState({ previousScrollY: hasWindow() && window.scrollY })
  }

  handleMenuUser = () => {
    if (this.state.isMobileMenuOpen) {
      this.toggleMobileMenu()
    }
    this.setState({ isMenuUserVisible: !this.state.isMenuUserVisible })
  }

  closeTooltip = () => {
    this.setState({ isMenuUserVisible: false })
  }

  toggleMobileMenu = () => {
    const { previousScrollY, previousHeaderPosition } = this.state
    const mobileNavigation = hasWindow() && document.querySelector('.mobile-navigation__container')

    this.setState({ animation: null })
    window.setTimeout(() => this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen }, () => {
      toggleBodyScroll(this.state.isMobileMenuOpen, previousScrollY, previousHeaderPosition, 0)
      mobileNavigation.style.cssText = 'position:fixed; top:0; left:0;'
    }), MENU_MOBILE_ANIMATION_DURATION)
  }

  closeMobileMenuWhenResizedToDesktop = () => {
    if (window.innerWidth >= 768 && this.state.isMobileMenuOpen) {
      toggleBodyScroll(false)
      this.setState({ isMobileMenuOpen: false })
    }
  }

  handleState = (name, value) => {
    this.setState({ [name]: value })
  }

  hideAd = () => this.setState({ isAdEnabled: false })

  render () {
    const { isAdEnabled, isMenuUserVisible, animation, isMobileMenuOpen } = this.state
    const { festivalName, user, router, linkCheck, t } = this.props
    const isUser = user && !isEmpty(user)
    const isBlack = router.route === '/kinley' || router.route === '/cola'
    const isAdvertisementShown = (router.route && router.route.match(/^\/festival/)) && isAdEnabled

    return (
      <div className={bemCx('mobile-navigation__container', [{ 'black': isBlack }])}>
        {isAdvertisementShown && <MobileAdvertisement onClick={this.hideAd} festivalCode={festivalName} />}
        <div className='mobile-navigation'>
          { festivalName === 'gw' ?
            <GwLogo className='mobile-navigation' festivalName={'gw'} festivalUrl={'main'} isBlack={false} t={t} /> :
            <Logo
              className='mobile-navigation'
              festivalName={festivalName}
              festivalUrl={getNewClientListingLink(festivalName)}
              isBlack={isBlack} t={t}
            />}
          <div className='mobile-navigation__content'>
            <div
              className='mobile-navigation__user-menu'
              onClick={isUser ? this.handleMenuUser : () => this.props.openModal(MODAL_NAMES.AUTHENTICATE)}
            >
              <span className='i-user-circle desktop-navigation__user-name-icon' />
              <DropdownTransitionWrapper>
                {isMenuUserVisible && <UserMenu
                  closeTooltip={this.closeTooltip}
                  isBlack={isBlack}
                  newClient={getIsFestivalPurchaseFlowNewClient(festivalName)}
                />}
              </DropdownTransitionWrapper>
            </div>
            <Hamburger active={isMobileMenuOpen} onClick={this.toggleMobileMenu} />
          </div>
        </div>
        {isMobileMenuOpen &&
          <MobileMenu
            isMobileMenuOpen={isMobileMenuOpen}
            animation={animation}
            handleState={this.handleState}
            router={router}
            linkCheck={linkCheck}
            isBlack={isBlack}
            selectedFestivalCode={this.props.selectedFestivalCode}
            isListingRoute={this.props.isListingRoute}
          />
        }
      </div>
    )
  }
}
