import bemCx from 'bem-modifiers'
import { withNamespaces } from '>/i18n'

import Link, { Redirect } from '~/components/Link'
import ImageTag from '~/components/ImageTag'
import UserMenu from '~/components/UserMenu/UserMenuGql'
import DropdownTransitionWrapper from '~/components/DropdownTransitionWrapper'
// import LanguageSwitcher from '~/components/LanguageSwitcher'

import { HEADER_LINK_NAMES } from '~/utils/consts'
import { getLogo, getFaqRoute, getFestivalUrl } from '~/utils/helpers'
import config from '~/config'
import { getIsFestivalPurchaseFlowNewClient } from '~/utils/newClient'
import { getNewClientListingLink } from '../../utils/newClient'

const {
  ABOUT,
  FAQ,
  SIDE_EVENTS,
  GIFT_EVENTS,
  PARTNERS,
  CAREERS,
  JOIN_US,
  BLOG
} = HEADER_LINK_NAMES

export const CcLogo = ({ className, festivalUrl, festivalName, isBlack, t }) =>
  <div className={`${className}__logo-wrapper`}>
    <Link route={festivalUrl} className={bemCx(`${className}__logo-container`, { 'double': festivalName })}>
      <ImageTag
        className={`${className}__logo`}
        src={isBlack ? 'assets/logo/logo-club-white.svg' : getLogo(festivalName)}
        alt=''
      />
    </Link>
  </div>

export const Logo = ({ className, festivalUrl, festivalName, isBlack, t }) =>
  <div className={`${className}__logo-wrapper`}>
    <Link route={festivalUrl} className={bemCx(`${className}__logo-container`, { 'double': festivalName })}>
      <ImageTag
        className={bemCx(`${className}__logo`, [festivalName])}
        src={isBlack ? 'assets/logo/logo-club-white.svg' : getLogo(festivalName)}
        alt=''
      />
    </Link>
  </div>

export const GwLogo = ({ className, festivalUrl, festivalName, isBlack, t }) =>
  <div className={`${className}__logo-wrapper`}>
    <Link route={festivalUrl} className={bemCx(`${className}__logo-container`, { 'double': festivalName })}>
      <ImageTag
        className={`${className}__logo`}
        src={'assets/logo/logo-festival-gw-black.png'}
        alt=''
      />
    </Link>
    {festivalName && <>
      <Link
        className={bemCx(`${className}__logo-container`, 'additional')}
        route={'main'}
      >
        <ImageTag
          className={`${className}__logo-second`}
          src={isBlack ? 'assets/logo/beefeater-second.png' : 'assets/logo/beefeater.png'}
          alt=''
        />
      </Link>
    </>}
  </div>

const uniqueFestivals = ['gw', 'cc']

export const isUniqueFestival = (festivalName) => {
  return uniqueFestivals.includes(festivalName)
}

export default withNamespaces(['common'])(({
  modifiers,
  isMenuUserVisible,
  handleMenuUser,
  handleScrollTo,
  closeTooltip,
  linkCheck,
  user,
  locationStr,
  festivalName,
  festivalId,
  selectedFestivalCode,
  isBlack,
  partnersLink,
  isUserVerified,
  voucherLink,
  careersLink,
  t
}) => {
  const isPurchaseFlowNewClient = getIsFestivalPurchaseFlowNewClient(festivalName)

  return (
    <div className={bemCx('desktop-navigation', modifiers)}>
      {festivalName === 'gw' &&
        <GwLogo className='desktop-navigation'
          festivalUrl={getFestivalUrl(festivalName || selectedFestivalCode)}
          festivalName={festivalName}
          isBlack={isBlack}
          t={t}
        />
      }
      {festivalName === 'cc' &&
        <CcLogo className='desktop-navigation'
          festivalUrl={getFestivalUrl(festivalName || selectedFestivalCode)}
          festivalName={festivalName}
          isBlack={isBlack}
          t={t}
        />
      }
      { !isUniqueFestival(festivalName) &&
        <Logo className='desktop-navigation'
          festivalUrl={getNewClientListingLink(festivalName || selectedFestivalCode)}
          festivalName={festivalName}
          isBlack={isBlack}
          t={t}
        />}
      <div className='desktop-navigation__content'>
        <div className='desktop-navigation__links-list'>
          {linkCheck(ABOUT, festivalName) && <a
            onClick={e => handleScrollTo('explanation-section', 40, e)}
            className='desktop-navigation__link'
          >
            {t('links.about_club')}
          </a>}
          {linkCheck(FAQ, festivalName) && <Redirect
            route={getFaqRoute(festivalName || selectedFestivalCode)}
            className='desktop-navigation__link'
            newClient={isPurchaseFlowNewClient}
          >
            {t('links.faq')}
          </Redirect>}
          {linkCheck(PARTNERS, festivalName) && <a
            href={partnersLink.href}
            onClick={partnersLink.onClick}
            className='desktop-navigation__link'
          >
            {t('links.partners')}
          </a>}
          {linkCheck(SIDE_EVENTS, festivalName) && <Redirect
            route='chefsmenu'
            className='desktop-navigation__link'
          >
            {t('links.side_events')}
          </Redirect>}
          {festivalId && linkCheck(GIFT_EVENTS, festivalName) && <Redirect
            route='gift-events-listing'
            className='desktop-navigation__link'
            newClient={isPurchaseFlowNewClient}
          >
            {t('links.voucher_purchase')}
          </Redirect>}
          {!festivalId && linkCheck(GIFT_EVENTS, festivalName) && <Redirect
            route='gift-events-listing'
            className='desktop-navigation__link'
          >
            {t('links.gift_events')}
          </Redirect>}
          {linkCheck(JOIN_US, festivalName) && <a
            onClick={e => handleScrollTo('cooperation-section', 40, e)}
            className='desktop-navigation__link'
          >
            {t('links.join_us')}
          </a>}
          {linkCheck(CAREERS, festivalName) && <a
            href={isPurchaseFlowNewClient
              ? `${config().newClubUrl}/career`
              : careersLink}
            target='_blank'
            className='desktop-navigation__link'
          >
            {t('links.careers')}
          </a>}
          {linkCheck(BLOG, festivalName) && <Redirect
            route='blog'
            className='desktop-navigation__link'
          >
            {t('links.blog')}
          </Redirect>}
        </div>
        {/* <LanguageSwitcher /> */}
        <div
          className={bemCx('desktop-navigation__user-menu', { 'active': isMenuUserVisible })}
          onClick={handleMenuUser}
        >
          <span className='i-user-circle desktop-navigation__user-name-icon' />
          <span className='desktop-navigation__user-name'>
            {isUserVerified ? `${user.firstName} ${user.lastName}` : t('links.login')}
          </span>
          <DropdownTransitionWrapper>
            {isMenuUserVisible && <UserMenu
              isBlack={isBlack}
              closeTooltip={closeTooltip}
              newClient={isPurchaseFlowNewClient}
            />}
          </DropdownTransitionWrapper>
        </div>
      </div>
    </div>
  )
})
