import React from 'react'
import { defer } from 'lodash'
import { withNamespaces } from '>/i18n'
import enhanceWithClickOutside from 'react-click-outside'
import { Router } from '>/server/routes'

import UserMenuView from './UserMenuView'

import { GET_USER_DATA } from '~/utils/queries.gql'

@withNamespaces(['tooltips'])
@enhanceWithClickOutside
export default class UserMenu extends React.Component {

  handleLogOut = async () => {
    const { signOut, client } = this.props
    const { router, pushRoute } = Router

    await signOut()
    client.resetStore()

    // Mobile Firefox needs this
    client.writeQuery({
      query: GET_USER_DATA,
      data: { user: null }
    })

    if (router.pathname === '/my-reservations' || router.pathname === '/reservation-edit') {
      pushRoute('/')
    }
  }

  handleClickOutside = () => {
    const { closeTooltip } = this.props
    defer(closeTooltip)
  }

  render () {
    return (
      <UserMenuView
        handleLogOut={this.handleLogOut}
        {...this.props}
      />
    )
  }
}
