import useUser from '~/hooks/useUser'

export default WrappedComponent => ({ skipUserQuery, ...props }) => {
  const { user, userLoading } = useUser(skipUserQuery)

  return (
    <WrappedComponent
      {...props}
      user={user}
      userLoading={userLoading}
    />
  )
}
